import React from "react"
import "./layout.css"

export default function Layout({ children }) {
  return <main>
    	<header>
      		<div><a class="header-link" href="/about">About</a></div>
      		<div><a class="header-link" href="/contact">Contact</a></div>
      		<div><a class="header-link-home" href="/">Home</a></div>
			<title>Jo Weidner</title>
    	</header>
    	{children}
	</main>
}