import * as React from "react"
import Layout from "../components/layout"

export default function Contact() {
  return <Layout>
	<div class="content">
      <p>josephineweidner@gmail.com</p>
      <p><a href="https://www.linkedin.com/in/josephine-weidner/" target="_blank">LinkedIn</a></p>
    </div>
  </Layout>
}